<template>
  <div id="app" style="width: 100vw; height: 100vh">
      <router-view></router-view>
  </div>
</template>



<script>

export default {
}
</script>